// extracted by mini-css-extract-plugin
export var customDescription = "settingsItem-module--customDescription--50f9e";
export var customDescriptionBefore = "settingsItem-module--customDescription-before--4d2f9";
export var settingsItemDescription = "settingsItem-module--settingsItem-description--c19b7";
export var settingsItemDescriptionBefore = "settingsItem-module--settingsItem-description-before--127e6";
export var settingsItemFields = "settingsItem-module--settingsItem-fields--c347a";
export var settingsItemInput = "settingsItem-module--settingsItem-input--224b6";
export var settingsItemInputWide = "settingsItem-module--settingsItem-input-wide--f7363";
export var settingsItemToggle = "settingsItem-module--settingsItem-toggle--d1fdb";
export var settingsItemToggleInfo = "settingsItem-module--settingsItem-toggleInfo--c81b3";
export var settingsItemToggleWrapper = "settingsItem-module--settingsItem-toggleWrapper--dfeb2";
export var settingsItemWrapper = "settingsItem-module--settingsItem-wrapper--b9437";